<!-- @Author: Yu_Bo 2023-09-05 18:28:44-->
<template>
    <div class="music_vue">
        <!-- 标题 -->
        <div class="vue_title">音乐管理</div>
        <!--  -->
        <div class="music_main">
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addMusic" size="small" icon="el-icon-plus">添加音乐</el-button>
                    <el-input v-model="name" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true" placeholder="搜索音乐名称"></el-input>
                    <el-select v-model="classify" size="small" @change="searchBtn" :clearable="true" placeholder="请选择分类">
                        <el-option v-for="item in classifyOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="status" size="small" @change="searchBtn" :clearable="true" placeholder="请选择状态">
                        <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column prop="name" label="音乐名称" width="200"></el-table-column>
                    <el-table-column label="音乐文件" width="250">
                        <template slot-scope="scope">
                            <div class="audio_box">
                                <span>{{scope.row.name}}.mp3</span>
                                <div class="img">
                                    <img src="@/assets/images/icon/play_icon2.png" alt="" v-if="scope.row.id==play" @click="audiostop">
                                    <img src="@/assets/images/icon/play_icon1.png" alt="" v-else @click="audioplay(scope.row)">
                                </div>
                                <audio v-if="scope.row.id==play" :src="scope.row.url" loop='true' autoplay="autoplay" ref="MusicPlay" hidden></audio>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="duration" label="音乐时长">
                        <template slot-scope="scope">
                            {{ $times.formatSeconds(scope.row.duration || 0, 2) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="materialCategory.name" label="音乐分类"></el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="添加时间"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="editMusic(scope.row)">编辑</el-button>
                            <el-button type="text" size="small" @click="delMusic(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="material" @close='closeMusic' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">请确认是否删除音乐，删除后将不可恢复</div>
            </div>
        </point-out>
        <!-- 添加/编辑 -->
        <add-edit ref="addedit" :list="classifyOptions" @affirm="getList"></add-edit>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import AddEdit from '../components/music/add_edit'// 添加/编辑
export default {
    components: { PointOut, EmptyData, PagingPage, AddEdit },
    props: {},
    data() {
        return {
            // 名称
            name: '',
            // 分类
            classify: '',
            classifyOptions: [],
            // 状态
            status: '',
            statusOptions: [
                { value: 1, label: "开启" },
                { value: 2, label: "关闭" },
            ],
            // 
            list: [],
            loading: false,
            //
            page: 1,
            limit: 10,
            total: 0,
            // 
            delId: null,
            play: 'none',  //播放语音
        };
    },


    created() {
        this.getCategory()
        this.getList()
    },


    methods: {
        audioplay(val) {
            this.play = val.id
        },
        audiostop() {
            this.play = 'none'
        },
        // 分类
        getCategory() {
            this.$materialApi.musicCategory().then(res => {
                if (res.code == 1000) {
                    this.classifyOptions = res.result.cat_list
                }
            })
        },
        // 数据列表
        getList() {
            this.play = 'none'
            this.loading = true
            var params = {
                page: this.page,
                limit: this.limit,
                name: this.name,
                status: this.status,
                category_id: this.classify,
            }
            this.$materialApi.musicList(params).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 筛选搜索
        searchBtn() {
            this.page = 1
            this.total = 0
            this.getList()
        },
        // 添加
        addMusic() {
            this.$refs.addedit.openDialog()
        },
        // 编辑
        editMusic(info) {
            var obj = JSON.parse(JSON.stringify(info))
            this.$refs.addedit.openDialog(obj)
        },
        // 删除
        delMusic(info) {
            this.delId = info.id
            this.$refs.dialogTip.openDialogBtn()
        },
        // 确定删除
        closeMusic() {
            if (this.delId != null) {
                this.$materialApi.musicListDel(this.delId).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        this.delId = null
                        if (this.list.length == 1) {
                            this.searchBtn()
                        } else {
                            this.getList()
                        }
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }
        },
        // 修改状态
        changeSwitch(val, info, index) {
            var params = {
                id: info.id,
                status: val
            }
            this.$materialApi.musicListChange(params).then(res => {
                if (res.code == 1000) {
                    this.list[index].status = val
                    this.$succMsg(res.message)
                } else {
                    this.list[index].status = val == 1 ? 2 : 1
                    this.$errMsg(res.message)
                }
            })
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
    }
}
</script>
<style lang='scss' scoped>
.music_vue {
    width: 100%;

    .audio_box {
        display: flex;
        align-items: center;
        span {
            color: #666666;
            font-size: 14px;
            padding-right: 6px;
        }
        .img {
            cursor: pointer;
            width: 16px;
            height: 16px;
            img {
                width: 16px;
                height: 16px;
            }
        }
    }
    .music_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
        }
    }
}
</style>